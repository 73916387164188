
var LiveSearchJs = function() {

  var init = function(options) {
    var live_search = {
      selector: "#search input[name='search']",
      text_no_matches: options.text_empty,
      height: '50px'
    }

    // Initializing drop down list
    var html = '<div class="live-search"><ul></ul></div>';
    $(live_search.selector).after(html);

    $(live_search.selector).autocomplete({
      'source': function(request, response) {
        var filter_name = $(live_search.selector).val();
        var cat_id = 0;
        var live_search_min_length = options.module_live_search_min_length;
        if (filter_name.length < live_search_min_length) {
          $('.live-search').css('display', 'none');
        } else {
          var live_search_href = 'index.php?route=extension/module/elasticsearch&livesearch=1&limit=7&search=';
          var all_search_href = 'index.php?route=extension/module/elasticsearch/buttonSearch&livesearch=0&search=';
          if (cat_id > 0) {
            live_search_href = live_search_href + encodeURIComponent(filter_name) + '&cat_id=' + Math.abs(cat_id);
            all_search_href = all_search_href + encodeURIComponent(filter_name) + '&category_id=' + Math.abs(cat_id);
          } else {
            live_search_href = live_search_href + encodeURIComponent(filter_name);
            all_search_href = all_search_href + encodeURIComponent(filter_name);
          }


          //start promise
          var xhr;

          performPromise(live_search_href)
              .then((result) => {

                if (result == 0) {
                  xhr.abort();
                } else {
                  getElasticResult(result)
                }

              })
              .catch((error) => {
                console.log(error)
              })

          function performPromise(live_search_href) {
            return new Promise((resolve, reject) => {
              xhr = $.ajax({
                url: live_search_href,
                dataType: 'json',
                success: function(result) {
                  resolve(result)
                },
                error: function(error) {
                  reject(error)
                },
              })
            })
          }

          function getElasticResult(result) {
            var founded = result.results;
            $('.live-search ul li').remove();
            if (!$.isEmptyObject(founded)) {
              var show_image = options.module_live_search_show_image;
              var show_price = options.module_live_search_show_price;
              var show_description = options.module_live_search_show_description;
              var show_add_button = 0;
              const maxDisplayedResults = 15; // Maximum number of results to initially display
              let recordsDisplayed = 0; // Counter for displayed records

              $.each(founded, function(index, result) {
                  var html = '<li>';
                  // show_add_button
                  if (show_add_button) {
                    html += '<div class="product-add-cart">';
                    html += '<a href="javascript:;" onclick="cart.add(' + result.product_id + ', ' + result.minimum + ');" class="btn btn-primary">';
                    html += '<i class="fa fa-shopping-cart"></i>';
                    html += '</a>';
                    html += '</div>';
                  }
                  html += '<div class="row">';
                  html += '<a href="' + result.href + '" title="' + result.name + '">';
                  // show image
                  if (result.thumb && show_image) {
                    html += '<div class="product-image"><img alt="" src="' + result.thumb + '"></div>';
                  }
                  // show name & extra_info
                  html += '<div class="col-6 product-name">' + result.name;
                  if (show_description && result.description) {
                    //html += '<p>' + result.description + '</p>';
                  }
                  html += '</div>';
                  // show price & special price
                  if (show_price) {
                    if (result.special) {
                      html += '<div class="col-3 product-price"><span class="special">' + result.price + '</span><span class="price">' + result.special + '</span></div>';
                    } else {
                      html += '<div class="col-3 product-price"><span class="price">' + result.price + '</span></div>';
                    }
                  }
                  html += '</a>';
                  html += '</div>';

                  html += '</li>';
                  $('.live-search ul').append(html);
              });
              const showAllButtonHtml = '<li class="show-all-button"><a href="' + all_search_href + '" class="btn btn-primary">' + options.text_view_all_results + '</a></li>';
              $('.live-search ul').append(showAllButtonHtml);
            } else {
              var html = '';
              html += '<li style="text-align: center;height:10px;">';
              html += live_search.text_no_matches;
              html += '</li>';

              $('.live-search ul').html(html);
            }
            // $('.live-search ul li').css('height',live_search.height);
            $('.live-search').css('display', 'block');

            return false;
          }

          //end promise

        }
      },
      'select': function(product) {
        $(live_search.selector).val(product.name);
      }
    });

    $(document).bind("mouseup touchend", function(e) {
      var container = $('.live-search');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
      }
    });
  }

  return {
    //main function to initiate the module
    init: function(options) {
      console.log("Init function called!"); // Check if this is logged
      init(options);
    }
  };

}();

export default LiveSearchJs;
document.addEventListener('DOMContentLoaded', function() {
  LiveSearchJs.init({
    text_view_all_results: "Zobrazit vše",
    text_empty: "Nic nenalezeno.",
    module_live_search_show_image: "1",
    module_live_search_show_price: "1",
    module_live_search_show_description: 0,
    module_live_search_min_length: "1",
    module_live_search_show_add_button: "0"
  });
});