/**
 * Owl2row
 * @since 2.0.2
 */
;(function ($, window, document, undefined) {
    Owl2row = function (scope) {
            this.owl = scope;
            this.owl.options = $.extend({}, Owl2row.Defaults, this.owl.options);
            //link callback events with owl carousel here

            this.handlers = {
                'initialize.owl.carousel': $.proxy(function (e) {
                    if (this.owl.settings.owl2row) {
                        this.build2row(this);
                    }
                }, this)
            };

            this.owl.$element.on(this.handlers);
    };

    Owl2row.Defaults = {
        owl2row: false,
        owl2rowTarget: 'item',
        owl2rowContainer: 'owl2row-item',
        owl2rowDirection: 'ltr',
        // ltr
        // ltr,
        itemsPerRow: 4,
        // Number of products in each row
        maxRows: 1 // Maximum number of rows
      };
    
      Owl2row.prototype.build2row = function (thisScope) {
        var carousel = $(thisScope.owl.$element);
        var carouselItems = carousel.find('.' + thisScope.owl.options.owl2rowTarget);
        var itemsPerRow = thisScope.owl.options.itemsPerRow;
        var maxRows = thisScope.owl.options.maxRows;
        var totalItems = carouselItems.length;
    
        // Adjust itemsPerRow if necessary
        if (totalItems < itemsPerRow) {
          itemsPerRow = totalItems;
        }
    
        // Calculate the number of rows
        var numRows = Math.ceil(totalItems / itemsPerRow);
    
        // Ensure that only one row is created when totalItems is not divisible by itemsPerRow
        if (numRows > 1 && totalItems % itemsPerRow !== 0) {
          numRows = 1;
        }
    
        // If you want to allow more items per row when totalItems is not divisible, you can adjust itemsPerRow here.
        // For example, you can set itemsPerRow to a larger value:
        if (totalItems % itemsPerRow !== 0) {
          itemsPerRow = totalItems;
          numRows = Math.ceil(totalItems / itemsPerRow);
        }
    
        // Ensure that numRows does not exceed maxRows
        numRows = Math.min(maxRows, numRows);
        carousel.empty();
        for (var row = 0; row < numRows; row++) {
          var rowContainer = $('<div class="' + thisScope.owl.options.owl2rowContainer + '"/>');
          for (var i = row * itemsPerRow; i < Math.min((row + 1) * itemsPerRow, totalItems); i++) {
            var item = carouselItems[i];
            rowContainer.append(item);
          }
          carousel.append();
        }
        switch (thisScope.owl.options.owl2rowDirection) {
          case 'ltr':
            thisScope.leftToright(thisScope, carousel, carouselItems, itemsPerRow);
            break;
          default:
            thisScope.upTodown(thisScope, aEvenElements, aOddElements, carousel);
        }
      };
      Owl2row.prototype.leftToright = function (thisScope, carousel, carouselItems, itemsPerRow) {
        var o2wContainerClass = thisScope.owl.options.owl2rowContainer;
        var owlMargin = thisScope.owl.options.margin;
        var carouselItemsLength = carouselItems.length;
        var firsArr = [];
        var secondArr = [];
    
        delete carouselItems.length
        delete carouselItems.prevObject
    
        var entries = Object.entries(carouselItems);
        var grouped = [];
        for (var i = 0; i < entries.length; i++) {
          if (i % itemsPerRow === 0) {
            grouped.push([entries[i]]);
          } else {
            grouped[Math.floor(i / itemsPerRow)].push(entries[i]);
          }
        }
        var chunks = grouped.map(function (o) {
          return Object.fromEntries(o);
        });
        
        var rows = [];
    
        $.each(chunks, function (index, chunk) {
          var row = [];
        
          $.each(chunk, function (key, value) {
            var item = value; // Access the item from the chunk
            // Here, you can customize how you want to process the item
            row.push(item);
          });
        
          rows.push(row);
        });
        
        var columns = [];
        
        // Create columns with the first element from both chunks
        for (var i = 0; i < rows[0].length; i++) {
          var column = [];
          for (var j = 0; j < rows.length; j++) {
            column.push(rows[j][i]);
          }
          columns.push(column);
        }
    
        // Now, you can create rows with columns
        $.each(columns, function (colIndex, column) {
          var rowContainer = $('<div class="' + o2wContainerClass + '"/>');
    
          $.each(column, function (rowIndex, item) {
            // Customize how you want to handle each item in the column
            rowContainer.append(item);
          });
    
          // Append the rowContainer to the carousel
          carousel.append(rowContainer);
        });
      };

    Owl2row.prototype.upTodown = function(thisScope, aEvenElements, aOddElements, carousel){

        var o2wContainerClass = thisScope.owl.options.owl2rowContainer;
        var owlMargin = thisScope.owl.options.margin;

        $.each(aEvenElements, function (index, item) {

            var rowContainer = $('<div class="' + o2wContainerClass + '"/>');
            var evenElement = aEvenElements[index];

            evenElement.style.marginBottom = owlMargin + 'px';

            rowContainer
                .append(evenElement)
                .append(aOddElements[index]);

            carousel.append(rowContainer);
        });
    };

    /**
     * Destroys the plugin.
     */
    Owl2row.prototype.destroy = function() {
        var handler, property;

        for (handler in this.handlers) {
            this.owl.dom.$el.off(handler, this.handlers[handler]);
        }
        for (property in Object.getOwnPropertyNames(this)) {
            typeof this[property] !== 'function' && (this[property] = null);
        }
    };

    $.fn.owlCarousel.Constructor.Plugins['owl2row'] = Owl2row;
})( window.Zepto || window.jQuery, window,  document );