import 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import 'bootstrap';
import 'jquery.cookie';
import 'magnific-popup';
import '@fancyapps/fancybox';
require('bootstrap-icons/font/bootstrap-icons.css');

(function (global) {
    // $ and jQuery are function here...
    global.$ = $;
    global.jQuery = jQuery;
}(typeof window !== 'undefined' ? window : this));

//import main style scss and from this import subfiles
import '../stylesheet/style.scss';